/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Subtitle } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Program"}>
        <SiteHeader />

        <Column className="pb--60 pt--80" name={"wstęp-1"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Program</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--40" name={"informace"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--2 flex--center" anim={"4"} animS={"3"} style={{"maxWidth":1160}} columns={"2"}>
            
            <ColumnWrapper className="--center pb--16 pt--16" style={{"maxWidth":500}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Dzień 1"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":910}} content={"Poniedziałek"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":910}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--16 pt--16" style={{"maxWidth":500}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Dzień 2"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":910}} content={"Wtorek"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":910}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--16 pt--16" style={{"maxWidth":500}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Dzień 3"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":910}} content={"Środa"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":910}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--16 pt--16" style={{"maxWidth":500}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Dzień 4"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":910}} content={"Czwartek"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":910}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-ijj26f pb--60 pt--60" name={"kontakt"} layout={"l1"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/154/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: white;\">Czekamy na Ciebie!</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 fs--20" content={"Potwierdź udział"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>32. Międzynarodowa Konferencja Gospodarcza</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn4" content={"Program"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box lh--16" content={"<span style='color: var(--color-dominant)'>Pańska 18, Warszawa<br>info@twoje-stronyy.com<br>+48 797 811 2X0</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":449}} content={"<span style='color: var(--color-dominant)'>Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje.<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}